export const SEO_SWAP = {
  TITLE:
    process.env.VUE_APP_SEO_DEFAULT_TITLE ??
    'Trade Crypto on Top L2 Chains & Earn Rewards with DegenLayer!',
  DESCRIPTION:
    process.env.VUE_APP_SEO_DEFAULT_DESCRIPTION ??
    "Join DegenLayer's testnet campaign and engage in cross-chain trading across networks like BeraChain, Polygon POS, Arbitrum, Base, Blast, Cronos, BOB, Q, Burnt, BNB, Sophon, Specular, OKX, TEN, taiko, COINWEB, Etherium. Take part in our testnet to trade ETH, BTC, USDT, and more for a chance to earn exclusive rewards!",
  KEYWORDS:
    process.env.VUE_APP_SEO_DEFAULT_KEYWORDS ??
    'BeraChain, Base, Blast, Cronos, BOB, Q, Burnt, BNB, Sophon, Specular, OKX, TEN, taiko, COINWEB, Ethereum, Polygon POS, Arbitrum, DegenLayer, airdrop, testnet, giveaway, retrodrop, HONEY, BERA, L2 network, DeFi, Blueshift, trade, yield farming, $HONEY, $BERA, $DGLR, DGLR, $BLUES, WETH, MATIC, WBTC, BTC, Axelar, LayerZero, Xswap, thorchain, chainport, uniswap, pancakeswap, 1inch',
};

export const SEO_PORTFOLIOS = {
  TITLE: 'Maximize Yields by Providing Liquidity on Blueshift via DegenLayer!',
  DESCRIPTION:
    "Capitalize on high yield opportunities by participating in DegenLayer's testnet campaign. Provide liquidity across platforms like BeraChain, Etherium, Arbitrum, Base, Blast, Cronos, BOB, Q, Burnt, BNB, Sophon, Specular, OKX, TEN, taiko, COINWEB, and unlock potent financial returns with Blueshift portfolios.",
  KEYWORDS:
    'BeraChain, Base, Blast, Cronos, BOB, Q, Burnt, BNB, Sophon, Specular, OKX, TEN, taiko, COINWEB, Ethereum, Polygon POS, Arbitrum, DegenLayer, airdrop, testnet, giveaway, retrodrop, HONEY, BERA, L2 network, DeFi, Blueshift, trade, yield farming, $HONEY, $BERA, $DGLR, DGLR, BLUES, $BLUES, stablecoin, portfolio, liquidity, investment, Axelar, LayerZero, Xswap, thorchain, chainport, uniswap, pancakeswap, 1inch',
};

export const SEO_FARMING = {
  TITLE: 'Boost Your Earnings with Blueshift Farming on DegenLayer!',
  DESCRIPTION:
    "Experience the latest in DeFi farming through DegenLayer's testnet campaign. Explore extensive farming opportunities on platforms including BeraChain, Cronos, COINWEB, Base, Blast, BOB, Q, Burnt, BNB, Sophon, Specular, OKX, TEN, taiko, Etherium, Polygon POS, Arbitrum, and harvest substantial rewards with Blueshift, Axelar, LayerZero, Xswap, thorchain, chainport, uniswap, pancakeswap, 1inch.",
  KEYWORDS:
    'BeraChain, Base, Blast, Cronos, BOB, Q, Burnt, BNB, Sophon, Specular, OKX, TEN, taiko, COINWEB, Ethereum, Polygon POS, Arbitrum, DegenLayer, $BERA, $HONEY, token, stablecoin, farming, liquidity, rewards, Axelar, LayerZero, Xswap, thorchain, chainport, uniswap, pancakeswap, 1inch',
};

export const SEO_STAKING = {
  TITLE: "Stake Top Cryptos for Yield on DegenLayer's Blueshift!",
  DESCRIPTION:
    "Explore the frontier of staking with DegenLayer's testnet. Stake your WETH, WBTC, $BERA, and $HONEY on networks like BeraChain, Polygon POS, Arbitrum, Base, Blast, Cronos, BOB, Q, Burnt, BNB, Sophon, Specular, OKX, TEN, taiko, COINWEB, Etherium, and join the movement towards innovative yield generation.",
  KEYWORDS:
    'BeraChain, Base, Blast, Cronos, BOB, Q, Burnt, BNB, Sophon, Specular, OKX, TEN, taiko, COINWEB, Ethereum, Polygon POS, Arbitrum, DegenLayer, $BERA, $HONEY, token, stablecoin, farming, liquidity, token, yield, pool, staking, DeFi, Axelar, LayerZero, Xswap, thorchain, chainport, uniswap, pancakeswap, 1inch',
};

export const PREVIEW_IMAGE = 'https://app.blueshift.fi/blueshift-splash.png';

export const SEO_META_OG = {
  OG_TITLE: SEO_SWAP.TITLE,
  OG_SITE_NAME: SEO_SWAP.TITLE,
  OG_TYPE: 'website',
  OG_DESCRIPTION: SEO_SWAP.DESCRIPTION,
  OG_IMAGE: PREVIEW_IMAGE,
};

export const SEO_META_TWITTER = {
  TWITTER_CARD: 'summary',
  TWITTER_TITLE: SEO_SWAP.TITLE,
  TWITTER_DESCRIPTION: SEO_SWAP.DESCRIPTION,
  PREVIEW_IMAGE: PREVIEW_IMAGE,
};
